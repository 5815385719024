import React from 'react'
import styled from 'styled-components'
import Banner from '../Banner'
import Logo from '../Logo'
import Facebook from '../../Icons/Facebook'
import Instagram from '../../Icons/Instagram'
import LinkedIn from '../../Icons/LinkedIn'
import Twitter from '../../Icons/Twitter'

const Section = styled.section`
	min-height: 100vh;
	height: 100vh;
	width: 100vw;
	background-color: ${(props) => props.theme.body};
	display: flex;
	flex-direction: column;
	position: relative;
	color: ${(props) => props.theme.text};
`

const Container = styled.div`
	width: 75%;
	margin: 2rem auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid ${(props) => props.theme.text};

	@media (max-width: 48em) {
		flex-direction: column;
		width: 100%;
		& > *:first-child {
			width: 100%;
			margin-top: 2rem;
		}
	}
`

const Left = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const IconList = styled.div`
	display: flex;
	align-items: center;
	margin: 1rem auto;

	& > * {
		padding-right: 0.5rme;
		transition: all 0.2s ease;

		&:hover {
			transform: scale(1.2);
		}
	}
`

const MenuItems = styled.ul`
	list-style: none;
	width: 50%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(3, 1fr);
	grid-gap: 1rem;
`
const Item = styled.li`
	width: fit-content;
	cursor: pointer;
	&::after {
		content: '';
		display: block;
		width: 0%;
		height: 2px;
		background: ${(props) => props.theme.text};
		transition: width 0.3s ease;
	}
	&:hover::after {
		width: 100%;
	}
`

const Bottom = styled.div`
	width: 75%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media (max-width: 64em) {
		justify-content: center;
		width: 100%;
	}
`

const Footer = () => {
	const scrollTo = (id) => {
		const element = document.getElementById(id)

		element.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
			inline: 'nearest',
		})
	}

	return (
		<Section>
			<Banner />
			<Container>
				<Left>
					<Logo />
					<IconList>
						<a href="#" target="_blank" rel="noopener">
							<Facebook />
						</a>
						<a href="#" target="_blank" rel="noopener">
							<Instagram />
						</a>
						<a href="#" target="_blank" rel="noopener">
							<LinkedIn />
						</a>
						<a href="#" target="_blank" rel="noopener">
							<Twitter />
						</a>
					</IconList>
				</Left>
				<MenuItems>
					<Item onClick={() => scrollTo('home')}>Home</Item>
					<Item onClick={() => scrollTo('about')}>About</Item>
					<Item onClick={() => scrollTo('showcase')}>Showcase</Item>
					<Item onClick={() => scrollTo('faq')}>Faq</Item>
					<Item onClick={() => scrollTo('contact')}>Contact</Item>
				</MenuItems>
			</Container>
			<Bottom>
				<span>
					&copy; {new Date().getFullYear()} Lion Bicycles . All rights Reserved
				</span>
			</Bottom>
		</Section>
	)
}

export default Footer
