import React from 'react'
import styled from 'styled-components'
import bike1 from '../../assets/bikes/hawk.png'
import bike2 from '../../assets/bikes/count.png'
import bike3 from '../../assets/bikes/trot.png'
import bike4 from '../../assets/bikes/bike2.png'
import bike5 from '../../assets/bikes/bike3.png'
import bike6 from '../../assets/bikes/bike5.png'
import bike7 from '../../assets/bikes/bike7.png'
import bike8 from '../../assets/bikes/bike8.png'
import Button from '../Button'

const Section = styled.section`
	width: 100vw;
	height: 25rem;
	position: relative;
	border-top: 2px solid ${(props) => props.theme.text};
	border-top: 2px solid ${(props) => props.theme.text};

	background-color: ${(props) => `rgba(${props.theme.textRgba}, 0.9)`};

	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	@media (max-width: 64em) {
		width: 85%;
	}
	@media (max-width: 48em) {
		flex-direction: column;
		width: 100%;
		& > *:first-child {
			width: 100%;
			margin: 2rem 0.5rem;
		}
	}
`
const ImgContainer = styled.div`
	width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0.2;

	img {
		width: 15rem;
		height: auto;
	}
`

const Title = styled.h1`
	font-size: ${(props) => props.theme.fontxxxl};
	color: ${(props) => props.theme.body};
	padding: 1rem 2rem;
	z-index: 10;
	width: 35%;
	text-transform: capitalize;
	text-align: center;
	text-shadow: 1px 1px 2px ${(props) => props.theme.text};
	@media (max-width: 70em) {
		font-size: ${(props) => props.theme.fontlg};
		margin: 1rem;
		width: 100%;
	}

	@media (max-width: 48em) {
		align-self: center;
		text-align: center;
	}
	@media (max-width: 48em) {
		width: 90%;
	}
`
const SubTitle = styled.h4`
	font-size: ${(props) => props.theme.fontmd};
	color: ${(props) => props.theme.body};
	padding: 1rem 2rem;
	z-index: 10;
	width: 35%;
	text-transform: capitalize;
	text-shadow: 1px 1px 2px ${(props) => props.theme.text};
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	@media (max-width: 70em) {
		font-size: 12px;
		font-weight: 400;
		width: 100%;
	}

	h1,
	h2 {
		margin: 1rem auto;
	}
`

const ContactUs = styled.button`
	display: inline-block;
	background-color: ${(props) => props.theme.body};
	color: ${(props) => props.theme.text};
	outline: none;
	border: none;
	position: relative;
	font-size: ${(props) => props.theme.fontlg};
	font-weight: 600;
	padding: 1.5rem 3rem;
	border-radius: 50px;
	cursor: pointer;
	transition: all 0.2s ease;

	&:hover {
		transform: scale(0.9);
	}
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) scale(0);
		border: 2px solid ${(props) => props.theme.body};
		width: 100%;
		height: 100%;
		border-radius: 50px;
		transition: all 0.2s ease;
	}
	&:hover::after {
		transform: translate(-50%, -50%) scale(1);
		padding: 0.3rem;
	}
`

const AboutBanner = () => {
	return (
		<Section>
			<ImgContainer>
				<img src={bike1} alt="The Bikes" />
				<img src={bike2} alt="The Bikes" />
				<img src={bike3} alt="The Bikes" />
				<img src={bike5} alt="The Bikes" />
				<img src={bike7} alt="The Bikes" />
				<img src={bike8} alt="The Bikes" />
			</ImgContainer>
			<Title>Lion Bicycles Limited</Title>
		</Section>
	)
}

export default AboutBanner
