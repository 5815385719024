import React, { useState } from 'react'
import styled from 'styled-components'
import TypeWriterText from '../TypeWriterText'
// import Bike from '../../assets/bikes/count.png'
import Bike from '../../assets/mainbike.jpg'

const Section = styled.section`
	min-height: ${(props) => `calc(100vh - ${props.theme.navHeight})`};
	width: 100%;
	position: relative;
	/* background: linear-gradient(
		90deg,
		rgba(255, 255, 255, 1) 0%,
		rgba(255, 255, 255, 1) 58%,
		rgba(248, 255, 187, 1) 100%
	); */
	background: ${(props) => props.theme.body};
`

const Container = styled.div`
	width: 75%;
	min-height: 80vh;
	margin: 0 auto;

	display: flex;
	justify-content: center;
	align-items: center;
	@media (max-width: 64em) {
		width: 100%;
	}
	@media (max-width: 48em) {
		flex-direction: column-reverse;
		& > *:first-child {
			width: 100%;
		}
	}
`
const Box = styled.div`
	width: 50%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	@media (max-width: 64em) {
		overflow-x: hidden;
		width: 100%;
	}
`
const HeroImage = styled.div`
	width: 100%;
	height: auto;
`
const Home = () => {
	return (
		<Section id="home">
			<Container>
				<Box>
					<TypeWriterText />
				</Box>
				<Box>
					<HeroImage>
						<img
							// src="https://res.cloudinary.com/dhsjpmqz9/images/f_auto,q_auto,w_700,h_460,c_fill,g_auto/CS_Cycle_Hawk_Balloon_imkwrf/cs-cycle-ck6j27o8z0le50148hbrn0x1f.png"
							src={Bike}
							height="100%"
							width="100%"
							alt=" hero image"
						/>
					</HeroImage>
				</Box>
			</Container>
		</Section>
	)
}

export default Home
