import React, { useState } from 'react'
import styled from 'styled-components'
import Typewriter from 'typewriter-effect'
import Button from '../Button'

const Title = styled.h2`
	font-size: ${(props) => props.theme.fontxxl};
	text-transform: capitalize;
	width: 80%;
	color: ${(props) => props.theme.text};
	align-self: flex-start;

	span {
		text-transform: uppercase;
		font-family: 'Akaya Teliviagala', cursive;
	}
	.text-1 {
		color: blue;
	}
	.text-2 {
		color: rgb(242, 100, 35);
	}
	.text-3 {
		color: green;
	}
	@media (max-width: 70em) {
		font-size: ${(props) => props.theme.fontxl};
	}
	@media (max-width: 48em) {
		align-self: center;
		text-align: center;
	}
	@media (max-width: 48em) {
		width: 90%;
	}
`
const SubTitle = styled.h3`
	font-size: ${(props) => props.theme.fontlg};
	text-transform: capitalize;
	color: ${(props) => `rgba(${props.theme.textRgba}, 0.6)`};
	font-weight: 600;
	margin-bottom: 1rem;
	margin-top: 1rem;
	width: 80%;
	align-self: flex-start;

	@media (max-width: 70em) {
		font-size: ${(props) => props.theme.fontmd};
	}
	@media (max-width: 48em) {
		align-self: center;
		text-align: center;
	}
	@media (max-width: 48em) {
		width: 90%;
	}
`

const ButtonContainer = styled.div`
	width: 80%;
	align-self: flex-start;
	@media (max-width: 70em) {
		font-size: ${(props) => props.theme.fontsm};
		margin-bottom: 2rem;
	}
	@media (max-width: 48em) {
		align-self: center;
		text-align: center;
	}
	@media (max-width: 48em) {
		width: 90%;
	}
`

const TypeWriterText = () => {
	const [click, setClick] = useState(false)

	const scrollTo = (id) => {
		const element = document.getElementById(id)

		element.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
			inline: 'nearest',
		})

		setClick(!click)
	}
	return (
		<>
			<Title>
				Suppliers of all
				<Typewriter
					options={{ autoStart: true, loop: true }}
					onInit={(typewriter) => {
						typewriter
							.typeString('<span class="text-1">Premium</span>')
							.pauseFor(2000)
							.deleteAll()
							.typeString('<span class="text-2">Affordable</span>')
							.pauseFor(2000)
							.deleteAll()
							.typeString('<span class="text-3">Various</span>')
							.pauseFor(2000)
							.deleteAll()
							.start()
					}}
				/>
				Bicycles!
			</Title>
			<SubTitle>Looking for Bicycles? We have you covered.</SubTitle>
			<ButtonContainer onClick={() => scrollTo('about')}>
				<Button text="Explore" link="#about" />
			</ButtonContainer>
		</>
	)
}

export default TypeWriterText
