import GlobalStyles from './styles/GlobalStyles'
import { ThemeProvider } from 'styled-components'
import { light } from './styles/Themes'

import Navigation from './components/Navigation'
import Footer from './components/Footer'
import Home from './components/sections/Home'
import About from './components/sections/About'
import Showcase from './components/sections/Showcase'
import Faq from './components/sections/Faq'
import Contact from './components/sections/Contact'

function App() {
	return (
		<div className="App">
			<GlobalStyles />
			<ThemeProvider theme={light}>
				<Navigation />
				<Home />
				<About />
				<Showcase />
				<Faq />
				<Contact />
				<Footer />
			</ThemeProvider>
		</div>
	)
}

export default App
