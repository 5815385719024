import React from 'react'
import AboutBanner from './AboutBanner'
import styled from 'styled-components'

const Section = styled.section`
	min-height: 100vh;
	height: 0100%;
	width: 100vw;
	background-color: ${(props) => props.theme.body};
	display: flex;
	flex-direction: column;
	position: relative;
	color: ${(props) => props.theme.text};
`

const Container = styled.div`
	width: 75%;
	margin: 2rem auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;

	@media (max-width: 48em) {
		width: 100%;
		& > *:first-child {
			width: 100%;
			margin-top: 2rem;
		}
	}
`
const BoxContainer = styled.div`
	width: 75%;
	margin: 2rem auto;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 48em) {
		width: 100%;
		& > *:first-child {
			width: 100%;
			margin-top: 2rem;
		}
		flex-direction: column;
	}
`
const Box = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	@media (max-width: 70em) {
		width: 100%;
	}
`
const Title = styled.h2`
	font-size: ${(props) => props.theme.fontxxl};
	text-transform: capitalize;
	width: 80%;
	color: ${(props) => props.theme.text};
	align-self: flex-start;
	margin: 0 auto;
	@media (max-width: 70em) {
		font-size: ${(props) => props.theme.fontxl};
		margin: 2rem 0;
		width: 100%;
	}

	@media (max-width: 48em) {
		align-self: center;
		text-align: center;
	}
	@media (max-width: 48em) {
		width: 90%;
	}
`
const SubTitle = styled.h3`
	font-size: ${(props) => props.theme.fontlg};
	text-transform: capitalize;
	width: 80%;
	color: ${(props) => props.theme.text};
	align-self: flex-start;
	margin: 0 auto;
	@media (max-width: 70em) {
		font-size: ${(props) => props.theme.fontlg};
		margin: 2rem 0;
		width: 100%;
	}

	@media (max-width: 48em) {
		align-self: center;
		text-align: center;
	}
	@media (max-width: 48em) {
		width: 90%;
	}
`
const SubText = styled.p`
	font-size: ${(props) => props.theme.fontlg};
	width: 80%;
	color: ${(props) => props.theme.text};
	align-self: flex-start;
	margin: 1rem auto;
	font-weight: 400;
	@media (max-width: 70em) {
		font-size: 16px;
		font-weight: 400;
	}
`

const About = () => {
	return (
		<Section>
			<AboutBanner />
			<Container>
				<Box>
					<SubText>
						Lion Bicycles Limited is a fully registered international bicycle
						manufacturing company. The Company is managed by a team of
						professionals who have a strong ambition for development of
						affordable transportation and particularly, strong interest in
						bicycle production. The Company has invested heavily in the
						provision of bicycles at a commercial level. We also focus and
						target our sales and marketing to Non-Governmental Organisations
						(NGOs), Farmers, Miners, SMEs, Government workers. Most importantly
						Lion Bicycles has created its own supply chains through establishing
						of supply outlets managed by our employees in identified locations
						across southern Africa. Our target market also extends to other low
						income groups in Central, Southern and East Africa where we provide
						bicycles, spare parts and other support services
					</SubText>
				</Box>
			</Container>
			<Title> The Company</Title>

			<Container>
				<Box>
					<SubTitle>1. Company Mission Statement</SubTitle>
					<SubText>
						To grow and improve on the existing quality of bicycle products and
						production procedures while ensuring affordability and availability
						as well as to create an excellent customer relationship. We will
						ensure that everyone in every corner of Africa has access and can
						afford this basic need, which stands currently as the basic source
						of transport for most poor people.
					</SubText>
				</Box>
				<Box>
					<SubTitle>2. Company philosophy and vision </SubTitle>
					<SubText>
						To become one of the biggest and most successful commercial bicycle
						producing company in and around Southern Africa with focus in
						satisfying the southern African industry first and then focus in
						exports all across Africa.
					</SubText>
				</Box>
				<Box>
					<SubTitle>3. Company goals </SubTitle>
					<SubText>
						• To achieve the Production and sales capacity of 12 400 of bicycles
						each month in the three years period to 2025. <br />• To be the Top
						bicycle producing company by the 2025 year of operations in Africa.
					</SubText>
				</Box>
				<Box>
					<SubTitle>4. Target market </SubTitle>
					<SubText>
						Lion works with Lion works with various International
						Non-Governmental Organisations, Cooperatives, Small-Medium
						Enterprises, Farmers and Miners; we have established strong
						relationships with these existing groups and eventually sketch our
						way to being the trusted main suppliers of the major groups in the
						region and Africa as a whole.
					</SubText>
					<SubText>
						Our bicycles are sold at a cheaper price compared to the already
						existing pricings. As per our mission statement, we desire that
						every one should have access to this cheapest source of transport.
						We desire to add value in the African bicycle production industry,
						which will benefit both ourselves and the consumers of our products.
					</SubText>
					<SubText>
						We are also opening additional outlets in designated well-researched
						markets whole across the region and we employ our own staff in these
						outlets. This will mean retailers will easily access the bicycles
						from our trusted outlets and the communities will be free to
						purchase our bicycles from our outlets at cheaper retail prices.
						Lion Bicycles is also open to Clients in our immediate communities
						who desire to purchase the products and spares from the Head office
						warehouse.
					</SubText>
				</Box>
			</Container>

			<Title>Our Products</Title>
			<Container>
				<SubTitle>Support Services</SubTitle>
				<SubText>
					We have fully stocked warehouses which provide various bicycle spares
					parts, such as:
				</SubText>
				<BoxContainer>
					<Box>
						<ul>
							<li>Tyres</li>
							<li>hubs</li>
							<li>Sprokets</li>
							<li>Chains</li>
							<li>Bells</li>
							<li>Pumps</li>
							<li>Chain covers</li>
							<li>Reflectors</li>
						</ul>
					</Box>
					<Box>
						<ul>
							<li>Helmets</li>
							<li>Pedels</li>
							<li>Saddles</li>
							<li>Handle bars</li>
							<li>Carriers</li>
							<li>Lamps</li>
							<li>Mudguards</li>
						</ul>
					</Box>
				</BoxContainer>
			</Container>
			<SubTitle>Maintenance services</SubTitle>
			<SubText>
				We have a fully trained and dedicated team of mechanics that provide
				maintenance and other post warranty advisory services
			</SubText>

			<Title>Local Outlets</Title>
			<BoxContainer>
				<Box>
					<SubTitle>Chipata</SubTitle>
				</Box>
				<Box>
					<SubTitle>Petauke</SubTitle>
				</Box>
				<Box>
					<SubTitle>Sinda</SubTitle>
				</Box>
				<Box>
					<SubTitle>Katete</SubTitle>
				</Box>
				<Box>
					<SubTitle>Chama</SubTitle>
				</Box>
			</BoxContainer>
			<Title>Address and Contact Details</Title>
			<BoxContainer>
				<Box>
					<SubTitle>Address</SubTitle>
					<SubText>
						<h3>Zambia</h3>
						No 6082 Sibweni Road, Northmead, Lusaka, Zambia
					</SubText>
					<SubText>
						<h3>Malawi</h3>
						Sacraine house, Area 4. Box 30964 Lilongwe, Malawi
					</SubText>
				</Box>

				<Box>
					<SubTitle>Telephone</SubTitle>
					<SubText>
						<h3>Zambia</h3>
						+260 211 25 52 22 / <br /> +260 977 42 35 66
					</SubText>
					<SubText>
						<h3>Malawi</h3>
						+265 999 03 59 43
					</SubText>
				</Box>
				<Box>
					<SubTitle>Mobile</SubTitle>
					<SubText>
						+260 966 40 47 14 / <br />
						+260 977 42 35 66
					</SubText>
				</Box>
				<Box>
					<SubTitle>Email</SubTitle>
					<SubText>info@lionbicycles.com</SubText>
				</Box>
			</BoxContainer>
		</Section>
	)
}

export default About
