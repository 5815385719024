import React from 'react'
import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'
import Button from '../Button'
import Carousel from '../Carousel'
import { dark } from '../../styles/Themes'
import { Link } from 'react-router-dom'

const Section = styled.section`
	min-height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	background-color: ${(props) => props.theme.bodySec};
`
const Container = styled.div`
	width: 75%;

	margin: 0 auto;
	/* background-color: lightblue; */
	display: flex;
	justify-content: center;
	align-items: center;
	@media (max-width: 64em) {
		width: 85%;
	}
	@media (max-width: 48em) {
		flex-direction: column-reverse;
		width: 100%;
		& > *:first-child {
			width: 100%;
			margin-top: 2rem;
		}
	}
`
const Box = styled.div`
	width: 50%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	@media (max-width: 70em) {
		width: 100%;
	}
`

const Title = styled.h2`
	font-size: ${(props) => props.theme.fontxxl};
	text-transform: capitalize;
	width: 80%;
	color: ${(props) => props.theme.text};
	align-self: flex-start;
	margin: 0 auto;
	@media (max-width: 70em) {
		font-size: ${(props) => props.theme.fontxl};
		margin: 2rem 0;
		width: 100%;
	}

	@media (max-width: 48em) {
		align-self: center;
		text-align: center;
	}
	@media (max-width: 48em) {
		width: 90%;
	}
`
const SubText = styled.p`
	font-size: ${(props) => props.theme.fontlg};
	width: 80%;
	color: ${(props) => props.theme.text};
	align-self: flex-start;
	margin: 1rem auto;
	font-weight: 400;
	@media (max-width: 70em) {
		font-size: ${(props) => props.theme.fontsm};
		font-weight: 400;
	}
`
const SubTextLight = styled.p`
	font-size: ${(props) => props.theme.fontmd};
	width: 80%;
	color: ${(props) => `rgba(${props.theme.text},0.6)`};
	align-self: flex-start;
	margin: 0 auto;
	@media (max-width: 70em) {
		font-size: ${(props) => props.theme.fontsm};
		font-weight: 400;
	}
`
const ButtonContainer = styled.div`
	width: 80%;
	margin: 1.5rem auto;
	align-self: flex-start;
`

const About = () => {
	return (
		<Section id="about">
			<Container>
				<Box>
					<Carousel />
				</Box>
				<Box>
					<Title>About Lion Bicycles Limited</Title>
					<SubText>
						Lion Bicycles Limited is a fully registered international bicycle
						manufacturing company. The Company is managed by a team of
						professionals who have a strong ambition for development of
						affordable transportation and particularly, strong interest in
						bicycle production.
					</SubText>
					<SubTextLight>
						The Company has invested heavily in the provision of bicycles at a
						commercial level. We focus and target on a sales...
					</SubTextLight>
					<ButtonContainer>
						<ThemeProvider theme={dark}>
							<Link to="AboutPage">
								<Button text="Read More" />
							</Link>
						</ThemeProvider>
					</ButtonContainer>
				</Box>
			</Container>
		</Section>
	)
}

export default About
