import React from 'react'
import ReactDOM from 'react-dom'
// import './index.css'
import '../node_modules/normalize.css/normalize.css'
import App from './App'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AboutPage from './components/pages/AboutPage'
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<App />} />
				<Route index element={<App />} />
				<Route path="AboutPage" element={<AboutPage />} />
			</Routes>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
