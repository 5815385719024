import React, { useRef } from 'react'
import styled from 'styled-components'
import { keyframes } from 'styled-components'

import img1 from '../../assets/bikes/1.jpg'
import img2 from '../../assets/bikes/2.jpg'
import img3 from '../../assets/bikes/3.jpg'
import img4 from '../../assets/bikes/4.jpg'
import img5 from '../../assets/bikes/5.jpg'
import img6 from '../../assets/bikes/6.jpg'
import img7 from '../../assets/bikes/7.jpg'
import img8 from '../../assets/bikes/8.jpg'

const Section = styled.section`
	min-height: 100vh;
	width: 100vw;
	background-color: ${(props) => props.theme.bodySec};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
`

const move = keyframes`
	0%{ transform: translateX(100%)}
	100%{ transform: translateX(-100%)}



`
const Row = styled.div`
	white-space: nowrap;
	box-sizing: content-box;
	margin: 2rem 0;
	display: flex;
	animation: ${move} 20s linear infinite ${(props) => props.direction};

	@media (max-width: 64em) {
		animation: none;
		flex-direction: column;
	}
`
const ImgContainer = styled.div`
	width: 15rem;
	margin: 0 1rem;
	background-color: ${(props) => props.theme.body};
	border-radius: 20px;
	cursor: pointer;

	img {
		width: 100%;
		height: auto;
	}

	@media (max-width: 64em) {
		animation: none;
		flex-direction: column;
		margin: 2rem 0;
	}
`

const Details = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0.8rem 1rem;
	background-color: ${(props) => props.theme.text};
	border: 2px solid ${(props) => `rgba(${props.theme.bodyRgba}, 0.5 )`};
	flex-direction: column;
	flex-wrap: wrap;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;

	span {
		font-size: ${(props) => props.theme.fontsm};
		color: ${(props) => `rgba(${props.theme.text}, 0.5 )`};
		font-weight: 600;
		line-height: 1.5rem;
	}

	h1 {
		font-size: ${(props) => props.theme.fontmd};
		color: ${(props) => props.theme.body};
	}
`
const Title = styled.h2`
	font-size: ${(props) => props.theme.fontxxl};
	text-transform: capitalize;
	width: 80%;
	color: ${(props) => props.theme.text};
	margin: 2rem auto;

	@media (max-width: 64em) {
		font-size: 36px;
	}
`

const BikeItem = ({ img, tyre, make, frameset, passRef }) => {
	const play = (e) => {
		passRef.current.style.animationPlayState = 'running'
	}
	const pause = (e) => {
		passRef.current.style.animationPlayState = 'paused'
	}
	return (
		<ImgContainer onMouseOut={(e) => play(e)} onMouseOver={(e) => pause(e)}>
			<img src={img} alt="The Bicycles" width="300px" height="211px" />
			<Details>
				<div>
					<span> Tyre </span> <br />
					<h1>{tyre}</h1>
				</div>

				<div>
					<span>Frame/Size</span>
					<h1>{frameset}</h1>
				</div>
				<div>
					<span>Make</span>
					<h1>{make}</h1>
				</div>
			</Details>
		</ImgContainer>
	)
}

const Showcase = () => {
	const Row1Ref = useRef(null)
	const Row2Ref = useRef(null)
	return (
		<Section id="showcase">
			<Title>Showcase of Some Products</Title>
			<Row direction="none" ref={Row1Ref}>
				<BikeItem
					img={img1}
					make="Jet Power 28T"
					frameset="Steel - Rigid/55-60cm"
					tyre="Tyre Size: 28 x 1.5 inch  "
					passRef={Row1Ref}
				/>
				<BikeItem
					img={img2}
					make="Terrain 28T"
					frameset="Steel - Rigid/50cm"
					tyre="Tyre Size: 26 x 1.5 inch  "
					passRef={Row1Ref}
				/>
				<BikeItem
					img={img3}
					make="Jet Gold 28T"
					frameset="Roadster - Rigid/55-60cm"
					tyre="Tyre Size: 28 x 1.5 inch   "
					passRef={Row1Ref}
				/>
				<BikeItem
					img={img4}
					make="Drona Jet"
					frameset="Steel - Rigid/ 55cm"
					tyre="Tyre Size: 28 x 1.5 inch  "
					passRef={Row1Ref}
				/>
			</Row>
			<Row direction="reverse" ref={Row2Ref}>
				<BikeItem
					img={img5}
					make="Royal Classic 28T"
					frameset="Steel - Rigid/55-60cm"
					tyre="Size: 28 x 1.5 inch  "
					passRef={Row2Ref}
				/>
				<BikeItem
					img={img6}
					make="Hawk unisex 26T"
					frameset="Heavy Duty"
					tyre="Size: 26 x 1.5 inch  "
					passRef={Row2Ref}
				/>
				<BikeItem
					img={img7}
					make="Royal Gold 28T"
					frameset="Roadster - Rigid/55-60cm"
					tyre="Tyre Size: 26x1.5  "
					passRef={Row2Ref}
				/>
				<BikeItem
					img={img8}
					make="Terrain Lady"
					frameset="Steel - Rigid/50cm"
					tyre="Tyre Size: 26 x 1.5 inch   "
					passRef={Row2Ref}
				/>
			</Row>
		</Section>
	)
}

export default Showcase
