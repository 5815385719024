import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'
import Accordion from '../Accordion'

const Section = styled.section`
	min-height: 100vh;
	height: 100vh;
	width: 100vw;
	background-color: ${(props) => props.theme.body};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	color: ${(props) => props.theme.text};
`

const Title = styled.h1`
	font-size: ${(props) => props.theme.fontxxl};
	text-transform: uppercase;
	color: ${(props) => props.theme.text};
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1rem auto;
	padding-bottom: 1rem;
	border-bottom: 2px solid ${(props) => props.theme.text};
	width: fit-content;
`

const Container = styled.div`
	width: 75%;
	margin: 2rem auto;

	display: flex;
	justify-content: space-between;
	align-content: center;
`

const Box = styled.div`
	width: 45%;
`

const Faq = () => {
	const ref = useRef(null)

	gsap.registerPlugin(ScrollTrigger)
	useLayoutEffect(() => {
		const element = ref.current

		ScrollTrigger.create({
			trigger: element,
			start: 'top top',
			end: 'bottom',
			pin: true,
			pinSpacing: false,
			scrub: true,
		})

		return () => {
			ScrollTrigger.kill()
		}
	}, [])

	return (
		<Section ref={ref} id="faq">
			<Title>Faq</Title>
			<Container>
				<Box>
					<Accordion
						title="What kind of bicycles do you supply? "
						children="We supply all kinds of bicycles such as: Unisex 26inch Heavy Duty
						, Standard Classic 28inch (Male and Female)
						,Mountain bikes
						,Sport bikes
						,Kids Bikes"
					/>
					<Accordion
						title=" Do you offer after sales service? "
						children="Yes we do. We provide support and maintenance. "
					/>
					<Accordion
						title="Do you provide spare parts?"
						children="Yes we stock the following: - Tyres
						- Hubs
						- Sprokets
						- Chains
						- Bells
						- Pumps
						- Chain covers
						- Reflectors
						- Helmets
						- Pedels
						- Saddles
						- Handle bars
						- Carriers
						- Lamps
						- Mudguards"
					/>
				</Box>
				<Box>
					<Accordion
						title=" What brands of bicycles do you provide? "
						children="Our main brands are:
						Lion, Hawk and Roma
						But we also supply other brands upon request."
					/>
					<Accordion
						title="
						Do you provide credit facilities? "
						children="Yes we do sell on credit to salary based employees from approved companies."
					/>
					<Accordion
						title=" Do you assist with Orders and Tenders? "
						children="Yes we do. If you have a big order of bicycles or you have won a tender to supply bicycles, we can provide you with the necessary assistance and logistics. For more information, Kindly contact our Sales Manager at: info@lionbicycles.com"
					/>
				</Box>
			</Container>
		</Section>
	)
}

export default Faq
