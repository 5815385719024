import React from 'react'
import styled from 'styled-components'
import ContactSection from '../Contact'

const Section = styled.section`
	min-height: 100vh;
	height: 100%;
	width: 100vw;
	background-color: ${(props) => props.theme.bodySec};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	color: ${(props) => props.theme.text};
`

const Container = styled.div`
	width: 50%;
	margin: 2rem auto;

	display: flex;
	justify-content: center;
	align-content: center;
	@media (max-width: 64em) {
		width: 85%;
	}
	@media (max-width: 48em) {
		flex-direction: column-reverse;
		width: 100%;
		& > *:first-child {
			width: 100%;
			margin: 2rem 0.5rem;
		}
	}
`
const Title = styled.h1`
	font-size: ${(props) => props.theme.fontxl};
	text-transform: uppercase;
	color: ${(props) => props.theme.text};
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1rem auto;
	padding-bottom: 1rem;
	padding-top: 3rem;
	border-bottom: 2px solid ${(props) => props.theme.text};
	width: fit-content;
`
const Box = styled.div`
	width: 100%;
`
const Contact = () => {
	return (
		<Section id="contact">
			<Title>Get In Touch</Title>
			<Container>
				<Box>
					<ContactSection />
				</Box>
			</Container>
		</Section>
	)
}

export default Contact
