import React from 'react'
import styled from 'styled-components'
// import Logos from '../../assets/lionLogo.svg'
import Logos from '../../assets/lionlogo.jpeg'

const LogoText = styled.h3`
	color: #333;
	font-size: ${(props) => props.theme.fontlg};
	cursor: pointer;

	@media (max-width: 64em) {
		font-size: ${(props) => props.theme.fontmd};
	}
`

const LogoLink = styled.a`
	display: flex;
	align-items: center;
	@media (max-width: 40em) {
		flex-direction: column;
		margin: 1rem;
	}
`

const Logo = () => {
	return (
		<LogoLink href="#">
			<div>
				<img src={Logos} alt="Lendbox logo" height="64px" width="64px" />
			</div>
			<LogoText> Lion Bicycles</LogoText>
		</LogoLink>
	)
}

export default Logo
