import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-cards'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import { Pagination, Navigation, Autoplay, EffectCards } from 'swiper'

import img1 from '../../assets/bikes/l1.jpg'
import img2 from '../../assets/bikes/l2.jpg'
import img3 from '../../assets/bikes/l3.jpg'
import img4 from '../../assets/bikes/l4.jpg'
import img5 from '../../assets/bikes/l5.jpg'
import img6 from '../../assets/bikes/l6.jpg'
import img7 from '../../assets/bikes/l7.jpg'

const Container = styled.div`
	width: 35vw;
	height: 50vh;

	.swiper {
		width: 100%;
		height: 100%;
	}
	.swiper-slide {
		background-color: ${(props) => props.theme.body};
		border-radius: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	@media (max-width: 48em) {
		width: 70vw;
		height: 45vh;
	}
`

const Carousel = () => {
	return (
		<Container>
			<Swiper
				autoplay={{
					delay: 2000,
					disableOnInteraction: false,
				}}
				pagination={{
					type: 'fraction',
				}}
				navigation={true}
				scrollbar={{
					draggable: true,
				}}
				effect={'cards'}
				grabCursor={true}
				modules={[EffectCards, Pagination, Navigation, Autoplay]}
				className="mySwiper">
				<SwiperSlide>
					<img src={img1} alt="Terrain 26T" />
				</SwiperSlide>

				<SwiperSlide>
					<img src={img2} alt="Terrain 26T" />
				</SwiperSlide>

				<SwiperSlide>
					<img src={img3} alt="Terrain 26T" />
				</SwiperSlide>

				<SwiperSlide>
					<img src={img4} alt="Terrain 26T" />
				</SwiperSlide>

				<SwiperSlide>
					<img src={img5} alt="Terrain 26T" />
				</SwiperSlide>

				<SwiperSlide>
					<img src={img6} alt="Terrain 26T" />
				</SwiperSlide>

				<SwiperSlide>
					<img src={img7} alt="Terrain 26T" />
				</SwiperSlide>
			</Swiper>
		</Container>
	)
}

export default Carousel
