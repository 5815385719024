import GlobalStyles from '../../styles/GlobalStyles'
import { ThemeProvider } from 'styled-components'
import { dark, light } from '../../styles/Themes'
import Footer from '../Footer'
import About from './About'
import AboutNav from './AboutNav'

function AboutPage() {
	return (
		<div className="AboutPage">
			<GlobalStyles />
			<ThemeProvider theme={light}>
				<AboutNav />
				<About />
				<Footer />
			</ThemeProvider>
		</div>
	)
}

export default AboutPage
