import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { css } from 'styled-components'
import emailjs from '@emailjs/browser'

const sharedStyles = css`
	background-color: #eee;
	height: 40px;
	border-radius: 5px;
	border: 1px solid #ddd;
	margin: 10px 0 20px 0;
	padding: 20px;
	box-sizing: border-box;
`
const Form = styled.form`
	width: 100%;
	max-width: 600px;
	padding: 40px;
	background-color: ${(props) => props.theme.body};
	color: ${(props) => props.theme.text};
	border-radius: 10px;
	box-sizing: border-box;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);

	@media (max-width: 64em) {
	}
	@media (max-width: 40em) {
	}
`
const FormInput = styled.input`
	display: block;
	width: 100%;
	${sharedStyles}
`
const FormTextarea = styled.textarea`
	background-color: #eee;
	width: 100%;
	min-height: 100px;
	resize: none;
	${sharedStyles}
`
const Button = styled.button`
	display: inline-block;
	margin-top: 2rem;
	background-color: #f7797d;
	color: ${(props) => props.theme.body};
	outline: none;
	border: none;
	position: relative;
	font-size: ${(props) => props.theme.fontsm};
	padding: 0.8rem 2.3rem;
	border-radius: 50px;
	cursor: pointer;
	transition: all 0.2s ease;

	&:hover {
		transform: scale(0.9);
	}
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) scale(0);
		border: 2px solid #f7797d;
		width: 100%;
		height: 100%;
		border-radius: 50px;
		transition: all 0.2s ease;
	}
	&:hover::after {
		transform: translate(-50%, -50%) scale(1);
		padding: 0.3rem;
	}
`

const ContactSection = () => {
	const form = useRef()

	const sendEmail = (e) => {
		e.preventDefault()

		emailjs
			.sendForm(
				'service_jevp6yc',
				'template_cpi17tc',
				e.target,
				'uezNhI4xn8zfwYvC9',
			)
			.then(
				(result) => {
					console.log(result.text)
				},
				(error) => {
					console.log(error.text)
				},
			)

		e.target.reset()
	}

	return (
		<>
			<Form onSubmit={sendEmail} ref={form}>
				<h2>Contact Form</h2>
				<label htmlFor="name">Name</label>
				<FormInput type="text" name="user_name" />
				<label htmlFor="email">Email</label>
				<FormInput type="email" name="user_email" />
				<label htmlFor="phone">Phone</label>
				<FormInput type="text" name="user_phone" />
				<label htmlFor="question">Question</label>
				<FormTextarea name="message" placeholder="Type your message here" />

				<Button type="submit" value="send">
					Sumbit
				</Button>
			</Form>
		</>
	)
}

export default ContactSection
