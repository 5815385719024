export const light = {
	body: '#fff',
	bodySec: '#F6F6F6',
	text: '#202020',
	bodyRgba: ' 255, 255, 255',
	textRgba: '32, 32,32',
	carouselColor: '#EEEDDE',
	fontxs: '0.075em',
	fontsm: '0.875em',
	fontmd: '1em',
	fontlg: '1.25emem',
	fontxl: '2em',
	fontxxl: '3em',
	fontxxxl: '4em',
	fontButton: '0.875em',
	navHeight: '5rem',
}
export const dark = {
	body: '#F6F6F6',
	text: '#202020',
	bodyRgba: ' 255, 255, 255',
	textRgba: '32, 32,32',
	carouselColor: '#EEEDDE',
	fontxs: '0.075em',
	fontsm: '0.875em',
	fontmd: '1em',
	fontlg: '1.25emem',
	fontxl: '2em',
	fontxxl: '3em',
	fontxxxl: '4em',
	fontButton: '0.875em',
	navHeight: '5rem',
}
